*, body, p, h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito Sans', sans-serif;
}
*, body, p {
  font-size: 16px;
  color: #5c727d;
}
body, html {
  overflow-x: hidden; /* This prevents horizontal scrolling */
}
a {
  text-decoration: none;
  transition: all 0.5s;
}
h1, h2, h3, h4, h5, h6 {
  color: #071c1f;
}
h2 {
  font-size: 35px;
  font-weight: 800;
  line-height: 45px;
}
h3 {
  font-size: 30px;
  font-weight: 800;
  line-height: 40px;
}
h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 800;
}
h5 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
}
.site_btn {
  background-color: #ff5a3c;
  color: #fff;
  border-radius: 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  padding: 17px 40px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 1;
  margin-right: 15px;
  text-decoration: none;
  border: 1px solid #ff5a3c;
}
.site_btn:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  transition: all 0.3s;
  content: "";
  position: absolute;
  z-index: -1;
}
.site_btn:hover {
  color: #071c1f;
  background-color: #0b2c3d;
  border: 1px solid #071c1f;
}
.site_btn:hover::after {
  width: 100%;
}
.main_heading {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 20px;
  font-weight: 800;
  text-transform: capitalize;
}
.sub_title {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 25px;
  position: relative;
  color: #ff5a3c !important;
  text-transform: capitalize;
}
.sub_title::before {
  position: absolute;
  content: "";
  background-color: #ff5a3c;
  width: 100%;
  height: 100%;
  top: 50%;
  right: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  border-radius: 25px;
  opacity: 0.1;
}
.service_item {
  box-shadow: 0 16px 32px 0 rgba(7, 28, 31, 0.1);
  border: 1px solid #f6f6f6;
  margin-bottom: 30px;
  padding: 40px 30px 35px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  background-color: #fff !important;
  color: #000 !important;
  text-align: center;
}
.service_item::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0%;
  height: 4px;
  background-color: #ff5a3c;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}
.service_item:hover::before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.service_item:hover {
  box-shadow: 0 16px 32px 0 rgba(7, 28, 31, 0.1);
}
.service_item .service_info h3 {
  font-size: 22px;
  font-weight: 800;
  line-height: 1.3;
  margin-bottom: 15px;
  color: #071c1f;
  text-transform: capitalize;
}
.service_item .service_info .text_btn {
  color: #8cb2b2;
  text-decoration: none;
  transition: all 0.5s ease 0s;
  margin-top: 10px;
  font-weight: 600;
}
.service_item .service_info .text_btn i {
  font-size: 14px;
  margin-left: 5px;
  color: #8cb2b2;
}
.service_item:hover .service_info .text_btn, .service_item:hover .service_info .text_btn i {
  color: #ff5a3c;
}

.site_list ul {
  padding-left: 30px;
  list-style: none;
  margin-bottom: 0;
}
.site_list ul li {
  position: relative;
  margin-bottom: 8px;
}
.site_list ul li::before {
  position: absolute;
  content: "";
  left: -30px;
  width: 15px;
  height: 1px;
  background-color: #ff5a3c;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.site_list2 ul {
  padding-left: 30px;
  list-style: none;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}
.site_list2 ul li {
  position: relative;
  margin-bottom: 8px;
  flex: 0 0 auto;
  width: 33%;
  display: inline-block;
}
.site_list2 ul li::before {
  position: absolute;
  content: "";
  left: -30px;
  width: 15px;
  height: 1px;
  background-color: #ff5a3c;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.two_col_list li {
  width: 50%;
  float: left;
}
.inner_banner {
  background-color: #0b2c3d;
  padding: 50px 0px;
}
.inner_banner_content h2, .inner_banner_content h1 {
  font-size: 34px;
  font-weight: 800;
  color: #fff !important;
  line-height: 44px;
}
.inner_banner_content p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px;
  color: #fff;
}
.desktophide {
  display: none;
}

@media (max-width: 480px) {
  .mobilehide {
    display: none;
  }
  .desktophide {
    display: block;
  }
  *, body, p {
    font-size: 11px;
    line-height: 14px;
  }
  .site_list ul {
    padding-left: 0;
  }
  .site_list ul li {
    margin-bottom: 6px;
    padding-left: 20px;
  }
  .site_list ul li::before {
    left: 0;
    width: 8px;
  }
  .site_btn {
    font-size: 10px;
    padding: 7px 10px;
  }
  .site_title_box {
    margin-bottom: 10px;
  }
  .main_heading {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0px;
  }
  .inner_banner {
    padding: 20px 0px;
  }
  .inner_banner_content h2, .inner_banner_content h1 {
    font-size: 14px;
    line-height: 14px;
  }
  .inner_banner_content p {
    font-size: 10px;
    line-height: 12px;
  }
  h2 {
    font-size: 13px;
    line-height: 15px;
    font-weight: 800;
  }
  h3 {
    font-size: 13px;
    line-height: 15px;
    font-weight: 800;
  }
  h4 {
    font-size: 13px;
    line-height: 15px;
    font-weight: 800;
  }
  h5 {
    font-size: 13px;
    line-height: 15px;
    font-weight: 800;
  }
  
  .electrical_safety_inspection_sec img {
    margin-bottom: 30px;
  }
  .service_item {
    margin-bottom: 15px;
    padding: 20px 4px 20px;
  }
  .service_item .service_info h3 {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .service_item .service_info .text_btn i {
    font-size: 8px;
  }
  
}
.electrical_safety_welcome {
    padding: 60px 0px;
}
.electrical_service_sec {
    padding: 50px 0px;
    background-color: #f2f6f7;
}
.electrical_safety {
    padding: 50px 0px;
}
.electrical_problems_sec {
    background-color: #f2f6f7;
    padding: 50px 0px;
}
.inspection_benefits_sec {
    padding: 50px 0px;
}

@media (max-width: 480px) {
    .electrical_safety_welcome {
        padding: 30px 0px 20px;
    }
}
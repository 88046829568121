.payment_form_area {
	padding: 60px 0px;
}
.payment_form_area .row {
	background-color: #0b2c3d;
	box-shadow: 0px 0px 13px rgba(0,0,0,0.1);
	border-radius: 10px;
	overflow: hidden;
}
.payment_form_area .row .col-lg-6:first-child {
    background-color: #fff;
}
.payment_form_area .row .payment_form {
	padding: 30px 20px;
}
form .form-label {
	color: #071c1f;
}
form .form-control, form .form-select {
	padding: 10px 15px;
	color: #000;
	box-shadow: none !important;
	border-radius: 0px;
}
.testimonial_section {
    padding: 70px 0px;
    background-size: auto;
    background-position: top center;
    background-repeat: no-repeat;
}

.slick-slider {
    margin: 0 auto;
  }
  
  .slick-slide {
    padding: 20px 15px;
  }
  .slick-prev,
  .slick-next {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .slick-slider:hover .slick-prev,
  .slick-slider:hover .slick-next {
    opacity: 1;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'FontAwesome';
    font-size: 24px;
  }
  
  .slick-prev:before {
    content: '\f104';
  }
  
  .slick-next:before {
    content: '\f105';
  }
.slick-slider .slick-arrow {
    border: 1px solid #000;
    font-size: 16px;
    width: 40px;
    height: 40px;
}
.slick-slider .slick-arrow:before {
    font-size: 25px;
    color: #071c1f;
    opacity: 1;
}

.slick-slider .slick-prev {
    left: -81px;
}
.slick-slider .slick-next {
    right: -81px;
}



.testimonial_item {
    background-color: #1f1f1f;
    padding: 10px 10px;
    max-width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0 5px 20px 0 rgba(23, 44, 82, 0.1);
    box-shadow: 0 5px 20px 0 rgba(23, 44, 82, 0.1);
    border: 1px solid;
    border-color: #f1f8ff;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    overflow: hidden;
}
.testimonial_item::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 0%;
    height: 4px;
    background-color: #ff5a3c;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    opacity: 0;
    visibility: hidden;
}
.testimonial_item:hover::before {
    width: 100%;
    opacity: 1;
    visibility: visible;
}
.testimonial_item .testimoni_info p {
    font-size: 18px;
    list-style: 28px;
    margin-bottom: 20px;
}
.testimonial_item .testimoni_info p i {
    font-size: 30px;
    position: relative;
    top: 4px;
    margin-right: 10px;
    line-height: 1;
    transition: all 0.3s ease 0s;
}
.testimonial_item:hover .testimoni_info p i {
    color: #ff5a3c;
}
.testimonial_item .testimoni_info_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.testimonial_item .testimoni_info_inner .testimoni_img {
    max-width: 60px;
    margin-right: 15px;
    margin-bottom: 0;
}
.testimonial_item .testimoni_img img {
    border-radius: 100%;
}
.testimonial_item
  .testimoni_info_inner
  .testimoni_name_designation
  h5 {
    font-weight: 600;
    margin-bottom: 0;
}
.testimonial_item
  .testimoni_info_inner
  .testimoni_name_designation
  label {
    font-size: 14px;
    margin-bottom: 0;
    text-transform: uppercase;
}

@media (max-width: 480px) {
  .testimonial_section {
    padding: 40px 0px;
  }
  .testimonial_item {
    padding: 25px 25px 25px;
    margin-bottom: 0;
  }
  .testimonial_item .testimoni_info p i {
    font-size: 19px;
    top: 2px;
    margin-right: 5px;
  }
  .testimonial_item .testimoni_info p {
    font-size: 12px;
    list-style: 16px !important;
    margin-bottom: 10px;
  }
  .testimonial_item
  .testimoni_info_inner
  .testimoni_name_designation
  h5 {
    font-size: 12px;
    line-height: 19px;
  }
  .testimonial_item
  .testimoni_info_inner
  .testimoni_name_designation
  label {
    font-size: 10px;
  }
  .testimonial_item .testimoni_info_inner .testimoni_img {
    max-width: 40px;
    margin-right: 9px;
  }
}
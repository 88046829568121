.damp_seepage_inspection_section {
    padding: 50px 0px;
}
.video_section {
    padding: 50px 0px;
}
.water_leakage_services .service_item {
    min-height: 530px;
}
.service_item {
    min-height: auto;
}
.causes_common_symptoms_area {
    padding: 60px 0px;
    background-color: #f2f6f7;
}
.health_issues_area {
    padding: 60px 0px;
    background-color: #f2f6f7;
}
.more_content_sec {
    padding: 60px 0px;
}
.leakage_reasons_section {
    padding: 60px 0px;
    background-color: #f2f6f7;
}
.leakage_reasons_section h3 {
    margin-bottom: 20px;
}
.feature_item {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 30px;
    box-shadow: 3px 3px 4px rgba(0,0,0,0.1);
}
.feature_item img {
    height: 220px;
    width: 100%;
    object-fit: cover;
}
.feature_item h4 {
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    background-color: #fff;
    display: inline-block;
    font-size: 15px;
    padding: 3px 15px;
    margin-bottom: 0px;
}

.common_seepage_issues_section {
    padding: 60px 0px;
}
.common_seepage_issues_section h3 {
    margin-bottom: 20px;
}

@media (max-width: 480px) {
    .damp_seepage_inspection_section img {
        margin-bottom: 20px;
    }
    .leakage_reasons_section {
        padding: 30px 0px 20px;
        background-color: #f2f6f7;
    }
    .feature_item img {
        height: 130px;
    }
    .feature_item h4 {
        bottom: 10px;
        font-size: 9px;
        padding: 3px 5px;
    }
    .common_seepage_issues_section {
        padding: 30px 0px 20px;
    }
    .video_section {
        padding: 20px 0px;
    }
    .video_section iframe {
        height: 250px;
        margin-top: 15px;
    }
}
.footer_sec {
    background-color: #171b2a;
}
.footer_sec p, .footer_sec a, .footer_sec h3 {
    color: #fff;
}
.top_footer {
    padding: 80px 0px;
}
.footer_title h3 {
    font-size: 22px;
    margin-bottom: 25px;
    font-weight: 800;
}
.footer_menu ul {
    margin: 0;
    padding: 0;
}
.footer_menu ul li {
    list-style: none;
    margin-top: 0px;
    margin-bottom: 15px;
    position: relative;
    transition: all 0.3s ease 0s;
}
.footer_menu ul li:hover a {
    padding-left: 20px;
}
.footer_menu ul li a {
    position: relative;
    text-decoration: none;
    transition: all 0.3s ease 0s;
}
.footer_menu ul li a:hover {
    color: #ff5a3c;
}
.footer_menu ul li a::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    opacity: 0;
    visibility: hidden;
    margin-left: -20px;
    color: #ff5a3c;
}
.footer_menu ul li:hover a::before {
    opacity: 1;
    visibility: visible;
    margin-left: 0;
}

.foot_contact {
    padding: 0px;
    list-style: none;
    margin-bottom: 30px;
}
.foot_contact li {
    padding-left: 30px;
    position: relative;
    margin-bottom: 10px;
    color: #fff;
}
.foot_contact li i {
    position: absolute;
    left: 0;
    top: 5px;
    color: #ff5a3c;
}
.foot_contact li a {
    text-decoration: none;
}
.footer_sm ul {
    margin: 0;
    padding: 0;
}
.footer_sm ul li {
    font-size: 14px;
    margin: 0 10px 0 0;
}
.footer_sm > ul > li {
    list-style: none;
    display: inline-block;
    margin: 0 30px 0 0;
    font-size: 14px;
    font-weight: 700;
}
.footer_sm > ul > li a, .footer_sm > ul > li a i {
    color: #fff;
    transition: all 0.5s;
}
.footer_sm > ul > li a:hover, .footer_sm > ul > li a i:hover {
    color: #ff5a3c;
}
.copyright_footer {
    background-color: #282b38;
    padding: 20px 0px;
}
.copyright_text p {
    margin-bottom: 0px;
}
.copyright_menu ul {
    margin: 0;
    padding: 0;
}
.copyright_menu ul li {
    list-style: none;
    display: inline-block;
    margin-top: 0;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 600;
}
.copyright_menu ul li a {
    margin: 0;
}
.copyright_menu ul li a:hover {
    color: #ff5a3c;
}

@media (max-width: 480px) {
    .top_footer {
        padding: 30px 0px;
    }
    .footer_title h3 {
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 15px;
    }
    .footer_menu ul li {
        margin-bottom: 8px;
    }
    .top_footer .col-lg-3 {
        margin-bottom: 30px;
    }
    .top_footer .col-lg-3:last-child {
        margin-bottom: 0px;
    }
    .foot_contact li {
        padding-left: 20px;
    }
    .copyright_text {
        text-align: center;
        margin-bottom: 6px;
    }
    .copyright_menu.text-end {
        text-align: center !important;
    }
    .footer_menu ul li:hover a {
        padding-left: 0px;
    }
}
.header_area {
	box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
}
.top_header {
    background-color: #0b2c3d;
    padding: 8px 0px;
}
.top_bar_contact > ul {
    padding: 0;
    margin: 0;
}
.top_bar_contact > ul > li, .top_bar_contact > ul > li a {
    list-style: none;
    display: inline-block;
    margin: 0 30px 0 0;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
}
.top_bar_contact > ul > li > i, .top_bar_contact > ul > li > a > i {
    margin-right: 3px;
    color: #ff5a3c;
}

.header_social_media {
    text-align: right;
}
.navbar-brand img {
    width: 270px;
}
.header_social_media ul {
    margin: 0;
    padding: 0;
}
.header_social_media ul li {
    font-size: 14px;
    margin: 0 10px 0 0;
}
.header_social_media > ul > li {
    list-style: none;
    display: inline-block;
    margin: 0 30px 0 0;
    font-size: 14px;
    font-weight: 700;
}
.header_social_media > ul > li a, .header_social_media > ul > li a i {
    color: #fff;
    transition: all 0.5s;
}
.header_social_media > ul > li a:hover, .header_social_media > ul > li a i:hover {
    color: #ff5a3c;
}
.nav_header .navbar {
    padding: 0px;
}
.nav_header .navbar-nav {
    padding: 10px 0px;
}
.nav_header ul.navbar-nav li a, .nav_header ul.navbar-nav li p {
    font-size: 18px;
    padding: 12px 10px;
    display: inline-block;
    white-space: nowrap;
    color: #071c1f;
    font-weight: 600;
}
.nav_header ul.navbar-nav li a.active {
    color: #ff5a3c;
}
.nav_header ul.navbar-nav li a:hover, .nav_header ul.navbar-nav li p:hover {
    color: #ff5a3c !important;
}
.nav_header ul.navbar-nav li p.nav-link {
    margin-bottom: 0px !important;
}
.nav_header ul.navbar-nav li.nav-item {
    margin: 0px 8px;
}
.header_btn .site_btn {
    padding: 12px 15px !important;
    display: inline-block;
    color: #fff !important;
    margin-right: 0px !important;
}
.header_btn .site_btn:after {
    background: #0b2c3d;
}
.nav_header ul.navbar-nav li ul.dropdown-menu {
    margin: 0px;
    padding: 0px;
    border: none;
    border-radius: 0px;
    box-shadow: 0px 8px 10px rgba(0,0,0,0.1);
    top: 70px;
}
.nav_header ul.navbar-nav li ul.dropdown-menu a.dropdown-item {
    padding: 7px 17px;
    border-bottom: 1px solid rgba(0,0,0,0.07);
    transition: all 0.5s;
}
.nav_header ul.navbar-nav li ul.dropdown-menu a.dropdown-item:hover, .nav_header ul.navbar-nav li ul.dropdown-menu a.active {
    background-color: #ff5a3c;
    color: #fff !important;
}
.header_btn .site_btn.active {
    color: #fff !important;
}

@media (max-width: 480px) {
    .top_bar_contact > ul {
        display: flex;
    }
    .top_bar_contact > ul > li {
        flex: 0 0 50%;
        text-align: left;
    }
    .top_bar_contact > ul > li:last-child {
        text-align: right;
    }
    .navbar-brand img {
        width: 180px;
    }
    .navbar-collapse {
        position: absolute;
        top: 43px;
        z-index: 999;
        background-color: #fff;
        width: 100%;
        left: 0;
    }
    .top_bar_contact > ul > li, .top_bar_contact > ul > li a {
        margin: 0px 30px 0 0;
        font-size: 10px;
    }
    .nav_header ul.navbar-nav li a, .nav_header ul.navbar-nav li p {
        font-size: 13px;
        padding: 7px 10px;
    }
    .nav_header ul.navbar-nav li.nav-item {
        margin: 0px 10px 5px;
    }
}
.homeInspaction_section {
    padding: 60px 0px;
}
.prepurchase_section {
    padding: 60px 0px;
}
.inspection_includes_sec {
    padding: 50px 0px;
    background-color: #f2f6f7;
}
.services_sec {
    background-color: transparent;
}
.service_item {
    min-height: 360px;
}
.interior_and_exterior_sec {
    padding: 50px 0px;
    background-color: #f2f6f7;
}
.interior_and_exterior_points {
    padding: 50px 0px;
}
.electrical_safety_inspection_sec {
    padding: 50px 0px;
    background-color: #f2f6f7;
}
.plumbing_inspection_sec {
    padding: 20px 0px;
}
.homeInspaction_btn {
    background-color: #0b2c3d;
    padding: 30px 0px;
}
.homeInspaction_btn h3 {
    color: #fff;
}
.homeInspaction_btn .btn_section {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.homeInspaction_btn .btn_section .btn {
    flex: 0 0 30%;
}

@media (max-width: 480px) {
    .homeInspaction_section {
        padding: 20px 0px 20px;
    }
    .homeInspaction_section img {
        width: 70%;
        display: block;
        margin: 0 auto 20px;
    }
    .prepurchase_section {
        padding: 30px 0px;
    }
    .prepurchase_section img {
        width: 70%;
        display: block;
        margin: 0 auto 20px;
    }
    .services_sec {
        background-color: #f2f6f7;
        padding: 30px 0px;
    }
}
.home_banner {
	position: relative;
}
.home_banner .carousel-inner .carousel-item {
    background-color: #f2f6f7;
}
.home_banner .carousel-caption {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #071c1f;
    text-align: left;
}
.home_banner .carousel-caption .row {
    padding: 20px 0px 40px;
    min-height: 530px;
}
.banner_content {
    padding-right: 50px;
}
.banner_content h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
    font-weight: 800;
}
.banner_content p {
    /* padding-left: 30px; */
    color: #5c727d;
    /* border-left: 1px solid #576466; */
}
.banner_content ul li {
    margin-bottom: 0px;
}
.banner_content .site_btn {
    margin-top: 10px;
}
.home_banner .carousel-control-next, .home_banner .carousel-control-prev {
    position: absolute;
    top: 50% !important;
    bottom: auto !important;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px !important;
    height: 40px;
    padding: 0;
    color: #071c1f !important;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 1 !important;
    border: 1px solid #071c1f;
    transition: opacity .15s ease;
}
.home_banner .carousel-control-prev {
    left: 30px;
}
.home_banner .carousel-control-next {
    right: 30px;
}
.home_banner .carousel-control-next i, .home_banner .carousel-control-prev i {
    font-size: 25px;
    color: #071c1f;
}
.home_banner .carousel-indicators [data-bs-target] {
    background-color: #071c1f;
}
.recognizedby_img {
	position: absolute;
	right: 130px;
	top: 10px;
	z-index: 9;
}
.recognizedby_img h4 {
	color: #ff5a3c;
	font-size: 20px;
	line-height: 25px;
}
.recognizedby_img h4 img {
	display: block;
	margin-left: 70px;
	width: 195px;
	filter: brightness(0);
}
.counter_area .row {
    box-shadow: 0 16px 32px 0 rgba(7, 28, 31, 0.1);
    padding: 20px 40px;
    background-color: #fff !important;
    margin-top: -60px;
    position: relative;
}
.counter_area .counter_item {
    position: relative;
}
.counter_area .counter_item:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 79px;
    right: 0px;
    top: 0px;
    background-color: #ff5a3c;
}
.counter_area .col-lg-4:last-child .counter_item:after {
    display: none;
}
.counter_item span.counter {
    font-size: 45px;
    line-height: 55px;
    font-weight: 800;
    color: #071c1f;
}
.counter_item span.counter_sign {
    font-size: 45px;
    line-height: 55px;
    font-weight: 800;
    color: #071c1f;
}
.counter_item h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;
}
.about_sec {
    padding: 100px 0px;
}
.about_content p {
  color: #5c727d;
}
.about_content .site_btn {
    margin-top: 30px;
}
.services_sec {
    background-color: #f2f6f7;
    padding: 70px 0px;
}
.site_title_box {
    margin-bottom: 30px;
}
.home_inspection_sec {
    padding: 70px 0px;
    background-color: #f2f6f7;
}
.inspection_coverage {
    padding: 70px 0px;
}
.inspection_coverage_item {
    margin-bottom: 35px;
}
.inspection_coverage_item h4 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: 5px;
}
.services_area {
    background-color: #f2f6f7;
    padding: 70px 0px;
}
.service_content {
    padding: 50px 0px;
}
.services_tab {
    border: none;
    position: relative;
    margin-top: 20px;
}
.services_tab:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #ff5a3c;
    top: 50%;
}
.services_tab li.nav-item {
    margin-right: 80px;
    position: relative;
}
.services_tab li.nav-item:last-child {
    margin-right: 0px;
}
.services_tab li.nav-item button {
    background-color: #fff !important;
    border: 1px solid #ff5a3c !important;
    margin: 0px;
    border-radius: 0;
    color: #ff5a3c !important;
}
.services_tab li.nav-item button.active, .services_tab li.nav-item button:hover {
    background-color: #ff5a3c !important;
    border: 1px solid #ff5a3c !important;
    color: #fff !important;
}

.cta-section {
    position: relative;
    padding: 80px 0px;
    background-color: #1b1d21;
}
.cta-section .pattern-layer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-repeat: repeat-x;
    animation: slide 100s linear infinite;
    -webkit-animation: slide 100s linear infinite;
}
@keyframes slide {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 1920px 0;
    }
}

.cta-section h2 {
    font-size: 30px;
    color: #fff;
    font-weight: 800;
    line-height: 40px;
    margin-bottom: 0px;
}


@media (max-width: 480px) {
    .banner_content {
        padding-right: 0;
    }
    .banner_content h3 {
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 10px;
        font-weight: 800;
    }
    .recognizedby_img h4 {
        font-size: 7px;
        line-height: 10px;
    }
    .recognizedby_img h4 img {
        margin-left: 30px;
        width: 75px;
    }
    .recognizedby_img {
        right: 10px;
        top: 10px;
    }
    .home_banner .carousel-caption .row {
        min-height: 270px;
        padding-bottom: 70px;
    }
    .home_banner .carousel-control-next, .home_banner .carousel-control-prev {
        top: 35% !important;
    }
    .banner_content {
        padding-right: 30px;
        padding-left: 30px;
    }
    .home_banner .carousel-control-prev {
        left: 10px;
    }
    .home_banner .carousel-control-next {
        right: 10px;
    }
    .home_banner .carousel-control-next, .home_banner .carousel-control-prev {
        width: 20px !important;
        height: 20px;
        padding: 0;
    }
    .home_banner .carousel-control-next i, .home_banner .carousel-control-prev i {
        font-size: 10px;
        color: #071c1f;
    }
    .banner_content .site_btn {
        margin-top: 10px;
    }
    .banner_content p {
        padding-left: 0px;
        border-left: none;
    }
    .counter_area .row {
        padding: 20px 10px;
    }
    .counter_area .counter_item {
        padding: 0px 10px;
    }
    .counter_item span.counter {
        font-size: 14px;
        line-height: 15px;
    }
    .counter_item span.counter_sign {
        font-size: 14px;
        line-height: 15px;
    }
    .counter_item h5 {
        font-size: 10px;
        line-height: 14px;
    }
    .counter_area .counter_item:after {
        height: 30px;
    }
    .about_sec img {
        width: 60%;
        margin: 0 auto;
        display: block;
    }
    .about_content .site_btn {
        margin-top: 10px;
    }
    .services_area {
        background-color: #f0f5f6;
        padding: 40px 0px;
    }
    .services_tab {
        display: block;
        margin-top: 0;
    }
    .services_tab:before {
        display: none;
    }
    .services_tab li.nav-item {
        margin-right: 0;
        margin-bottom: 4px;
    }
    .site_title_box {
        margin-bottom: 10px;
    }
    .service_content {
        padding: 20px 0px 0px;
    }
    .service_content img {
        width: 100%;
        margin-top: 20px;
    }
    .cta-section {
        padding: 30px 0px;
    }
    .cta-section h2 {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
        text-align: center !important;
    }
    .cta-section .text-end {
        text-align: center !important;
    }
    .inspection_coverage {
        padding: 40px 0px;
    }
    .inspection_coverage .site_title_box {
        margin-bottom: 20px;
    }
    .inspection_coverage_item {
        margin-bottom: 20px;
        text-align: left !important;
        position: relative;
        padding-left: 25px;
    }
    .inspection_coverage_item i {
        position: absolute;
        left: 0;
        font-size: 16px;
        color: #ff5a3c;
    }
    .inspection_coverage_item h4 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 800;
        margin-bottom: 5px;
    }
    .home_inspection_sec {
        padding: 40px 0px;
    }
    .home_inspection_sec img {
        width: 50%;
        margin-bottom: 20px;
    }
    
}